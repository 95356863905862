









































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { taskService } from "@/services/task.service";
// eslint-disable-next-line no-unused-vars
import { VisualTaskEntity } from "@/entities/VisualTask.entity";
// eslint-disable-next-line no-unused-vars
import { ClassificationTaskEntity } from "@/entities/ClassificationTaskEntity";

@Component
export default class Collections extends Vue {
  visualTasks: VisualTaskEntity[] = [];
  visualTasksHeaders = [
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Description", value: "description", sortable: false },
    { text: "Actions", value: "actions", sortable: false }
  ];

  classificationTasks: ClassificationTaskEntity[] = [];
  classificationTasksHeaders = [
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Description", value: "description", sortable: false },
    { text: "Actions", value: "actions", sortable: false }
  ];

  dialog: boolean = false;
  taskToDelete: number = -1;
  taskToDeleteType: string = "";

  mounted() {
    this.getVisualTasks();
    this.getClassificationTasks();
  }

  get isSuperuser() {
    return this.$store.getters.isSuperuser;
  }

  getVisualTasks() {
    taskService.getVisualTasks().then(response => {
      this.visualTasks = response.data;
    });
  }

  getClassificationTasks() {
    taskService.getClassificationTasks().then(response => {
      this.classificationTasks = response.data;
    });
  }

  deleteTask() {
    if (this.taskToDelete < 0) {
      return;
    }
    if (this.taskToDeleteType == "visual") {
      this.deleteVisualTask();
    } else {
      this.deleteClassificationTask();
    }
  }

  deleteVisualTask() {
    taskService.deleteVisualTask(this.taskToDelete).then(() => {
      this.getVisualTasks();
      this.closeDialog();
    });
  }

  openDialog(taskId: number, taskType: string) {
    this.taskToDelete = taskId;
    this.taskToDeleteType = taskType;
    this.dialog = true;
  }

  closeDialog() {
    this.taskToDelete = -1;
    this.taskToDeleteType = "";
    this.dialog = false;
  }

  deleteClassificationTask() {
    taskService.deleteClassificationTask(this.taskToDelete).then(() => {
      this.getClassificationTasks();
      this.closeDialog();
    });
  }

  selectVisualTask(task: any) {
    this.$router.push({
      path: "/annotate",
      query: { photoCollectionId: task.photo_collection, taskId: task.id }
    });
  }

  selectClassificationTask(task: any) {
    this.$router.push({
      path: "/classify",
      query: { photoCollectionId: task.photo_collection, taskId: task.id }
    });
  }
}
